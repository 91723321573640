import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ITextPortion, TextTypeEnum } from '../models/Teleprompter';
import { CopywriterService } from './show/copywriter.service';
import { PrompterSyncronizerService } from './prompter-syncronizer.service';
import { VoiceRecognitionSupportedStateService } from './voice-recognition-supported-state.service';
import { IProject } from '../models/project/project-model';
export interface ISpokenTime {
    text: string;
    time: number;
}

declare let webkitSpeechRecognition: any;
declare let webkitSpeechGrammarList: any;

interface SplitText {
    ai_text: Map<string, ITextPortion>;
}

@Injectable({
    providedIn: 'root',
})
export class VoiceRecognitionService implements OnDestroy {
    words: string[] = [];
    text: string = '';
    isNewText: boolean = true;
    slidingWindow = 8;

    stopWords = ['hmm', 'it', 'i', 'you', 'this', 'the', 'is', 'a', 'are', 'as', 'but'];

    recognition = null;
    speechRecognitionOn = false;

    public speechText = '';

    public splitText = new Map<string, ITextPortion>();
    public splitTextOnlyWords: ITextPortion[] = [];
    public currentIndex = 0;
    lastFoundWord = new BehaviorSubject<ITextPortion>(null);
    notifyScrollTeleprompter = new BehaviorSubject<ITextPortion>(null);
    notifySplitTextProcessed = new BehaviorSubject<SplitText>(null);

    splitterRegexp = new RegExp(
        `(?<${TextTypeEnum.Word}>\\w+)|(?<${TextTypeEnum.Space}>\\s+)|(?<${TextTypeEnum.Symbol}>[^\\s\\w]+)`,
        'g'
    );

    speechTempWords = '';
    jumpedToWord: boolean = false;
    private onDestroy$ = new Subject<void>();
    private firstTimeSpoke: boolean = false;
    private lastSpokenTime: number;

    private _firstTimeSpoken$ = new BehaviorSubject<ISpokenTime>(null);
    public firstTimeSpoken$ = this._firstTimeSpoken$.asObservable();
    private _lastTimeSpoken$ = new BehaviorSubject<ISpokenTime>(null);
    public lastTimeSpoken$ = this._lastTimeSpoken$.asObservable();

    public get getLastTimeSpoken() {
        return this._lastTimeSpoken$.value;
    }
    // text.match(/\w+|\s+|[^\s\w]+/g)
    constructor(
        private copywriter: CopywriterService,
        public prompterSync: PrompterSyncronizerService,
        private voiceRecognitionSupportedService: VoiceRecognitionSupportedStateService
    ) {
        if (
            typeof webkitSpeechRecognition !== 'undefined' &&
            this.voiceRecognitionSupportedService.getSpeechRecognitionSupported()
        ) {
            this.recognition = new webkitSpeechRecognition();
            console.log('webkitSpeechRecognition exists');
        } else {
            console.warn('webkitSpeechRecognition is not supported in this environment.');
            // Handle the case where speech recognition is not available
        }

        if (
            typeof webkitSpeechGrammarList !== 'undefined' &&
            this.voiceRecognitionSupportedService.getSpeechGrammarListSupported()
        ) {
            console.log('webkitSpeechGrammarList exists');
        } else {
            console.warn('webkitSpeechGrammarList is not supported in this environment.');
            // Handle the case where speech recognition is not available
        }
        this.init();

        this.prompterSync.currentPrompterText$.pipe(takeUntil(this.onDestroy$)).subscribe((newText) => {
            this.splitText = new Map<string, ITextPortion>();
            this.isNewText = true;
            this.text = newText;
            this.splitTextOnlyWords = [];
            this.lastFoundWord = new BehaviorSubject<ITextPortion>(null);
            if (!newText) {
                this.notifySplitTextProcessed.next({ ai_text: this.splitText });
                return;
            }
            this.splitText = new Map<
                string,
                {
                    text: string;
                    type: TextTypeEnum;
                    done: boolean;
                    uuid: string;
                    index: number;
                    newLine: boolean;
                }
            >();

            const words = this.text.toLowerCase().match(/\b\w+\b/g); // Convert to lowercase and extract words

            // Use a Set to keep only unique words.
            const uniqueWords = [...new Set(words)];

            // Create the grammar
            if (
                this.voiceRecognitionSupportedService.getSpeechRecognitionSupported() &&
                this.voiceRecognitionSupportedService.getSpeechGrammarListSupported()
            ) {
                const grammar = `#JSGF V1.0; grammar words; public <word> = ${uniqueWords.join(' | ')} ;`;
                const speechRecognitionList = new webkitSpeechGrammarList();
                speechRecognitionList.addFromString(grammar, 1);
                this.recognition.grammars = speechRecognitionList;
            }
            let lastMatch;
            let index = 0;

            while ((lastMatch = this.splitterRegexp.exec(newText))) {
                index++;

                // Split the text into 3 Groups: WORD, SPACE, and SYMBOL
                if (lastMatch) {
                    // Check for two or more consecutive newlines (e.g., \n\n, \n\n\n, etc.)
                    const newlineMatch = lastMatch[0].match(/\n+/); // This will match 1 or more \n characters
                    if (newlineMatch) {
                        const newlineCount = newlineMatch[0].length; // Count the number of consecutive \n characters

                        // Loop through the newlineCount and create that many newline objects, only if it's 2 or more
                        for (let i = 0; i < newlineCount; i++) {
                            const uuid = uuidv4();
                            this.splitText.set(uuid, {
                                text: '\n',
                                type: TextTypeEnum.Space, // Adjust if you have a specific type for newlines
                                done: false,
                                uuid: uuid,
                                newLine: true,
                                index: index + i, // Ensure index is correctly incremented for each \n
                            });
                        }

                        // Increment the index accordingly based on the number of newlines processed
                        index += newlineCount - 1;

                        continue; // Skip the rest of the loop for this iteration since we've processed the newlines
                    }

                    const word = lastMatch.groups[TextTypeEnum?.Word];
                    const space = lastMatch.groups[TextTypeEnum?.Space];
                    const symbol = lastMatch.groups[TextTypeEnum?.Symbol];

                    const uuid = uuidv4();
                    if (word) {
                        this.splitText.set(uuid, {
                            text: word,
                            type: TextTypeEnum.Word,
                            done: false,
                            uuid: uuid,
                            index: index,
                            newLine: false,
                        });
                        const lowerCased = word.toLowerCase();
                        if (!this.stopWords.some((word) => word === lowerCased)) {
                            this.splitTextOnlyWords.push({
                                text: lowerCased,
                                type: TextTypeEnum.Word,
                                done: false,
                                uuid: uuid,
                                index: index,
                                newLine: false,
                            });
                        }
                    } else if (space) {
                        this.splitText.set(uuid, {
                            text: space,
                            type: TextTypeEnum.Space,
                            done: false,
                            uuid: uuid,
                            index: index,
                            newLine: false,
                        });
                    } else if (symbol) {
                        this.splitText.set(uuid, {
                            text: symbol,
                            type: TextTypeEnum.Symbol,
                            done: false,
                            uuid: uuid,
                            index: index,
                            newLine: false,
                        });
                    }
                }

                // Avoid infinite loop
                if (!this.splitterRegexp.global) {
                    break;
                }
            }
            this.notifySplitTextProcessed.next({ ai_text: this.splitText });
            // let result = splitterRegexp.exec(newText);
            // console.log('All text', this.splitText)
            // console.log('Words', this.splitTextOnlyWords)
        });
    }

    init() {
        if (!this.recognition) {
            return;
        }

        this.recognition.interimResults = true;
        this.recognition.lang = 'en-US';
        this.recognition.addEventListener('result', (e) => {
            const transcript = Array.from(e.results)
                .map((result) => result[0])
                .map((result) => result.transcript)
                .join('');
            //   if (!(this.speechTempWords?.length > 0)) {
            //     return;
            //   }
            const currentTime = Date.now();
            if (transcript) {
                this.lastSpokenTime = currentTime;
                if (!this.firstTimeSpoke) {
                    this.firstTimeSpoke = true;
                    this._firstTimeSpoken$.next({ text: transcript, time: currentTime });
                }
                const regex = new RegExp(`\\b(${this.speechTempWords})\\b`);
                // console.log('regex', regex);
                let newAddition = transcript;

                /// Remove the previous words from the result to get only what's new
                /// Avoid replacing very short words
                // TODO - Fix new addition -
                if (this.speechTempWords?.length > 1) {
                    newAddition = transcript.toLowerCase().replace(regex, '');
                }

                /// Split to words
                let srTextWords = newAddition.match(/\w+/g);
                if (srTextWords) {
                    srTextWords = this.checkSentence(srTextWords);
                    srTextWords.forEach((word) => this.newWord(word));
                }
            }
            this.speechTempWords = transcript.toLowerCase();
        });
    }

    checkSentence(sentence) {
        const maxWindowSize = 10;
        const startTextIndex = Math.max(0, this.currentIndex - maxWindowSize);
        const recentTextWords = this.splitTextOnlyWords.slice(startTextIndex, this.currentIndex);

        for (let i = 0; i < sentence.length; i++) {
            const threeWords = sentence.slice(i, i + 2);
            const matches = threeWords.every((word) =>
                recentTextWords.some((textWord) => {
                    return textWord.text === word && textWord.done === true;
                })
            );
            if (matches) {
                // Delete all words from the start up to and including the matched sequence
                sentence = sentence.splice(0, i + 3); // This removes all words before and including the three words
                break; // Exit the loop after removal since the array has changed
            }
        }
        // Check in the last 10 words (Sliding window)
        // Check from the beginning of the sentence for at least 3 words sequence

        // Remove all the words we found matching (3 or more words)

        // Return the new sentence (might be empty)
        return sentence;
    }

    newWord(word) {
        if (this.isNewText) {
            this.isNewText = false;
            if (!this.jumpedToWord) {
                this.currentIndex = 0;
            }
        }
        this.jumpedToWord = false;
        const slidingWindowArr = this.splitTextOnlyWords.slice(
            this.currentIndex,
            Math.min(this.currentIndex + this.slidingWindow, this.splitTextOnlyWords.length)
        );
        for (let i = 0; i < slidingWindowArr.length; i++) {
            const slidingWord = slidingWindowArr[i];
            let counter = 0;
            // if (counter < (i - 4)) {
            //   break;
            // }
            if (
                (slidingWord.text === word && !slidingWord.done) ||
                (word == 'shuffle' && slidingWord.text == 'shuffll')
            ) {
                counter++;
                let found = false;
                this.splitTextOnlyWords.find((el) => {
                    if (word === el.text && !el.done && !found) {
                        el.done = true;
                        found = true;
                    }
                });
                const newIndex = this.currentIndex + i + 1;
                // run over the previous elements again invoke their events
                for (let f = 0; f <= i; f++) {
                    const originalFromSplitArray = this.splitText.get(slidingWindowArr[f].uuid);

                    if (originalFromSplitArray.events?.length > 0) {
                        this.invokeWordEvent(originalFromSplitArray);
                    }
                }
                this.currentIndex = newIndex;
                this.lastFoundWord.next(slidingWord);
                this.notifyScrollTeleprompter.next(slidingWord);
                break;
            }
        }
    }

    jumpToWord(word) {
        this.jumpedToWord = true;
        this.currentIndex = this.splitTextOnlyWords.findIndex((splitWord) => {
            return splitWord.uuid === word.uuid;
        });
        // for (let i = 0; i < this.splitTextOnlyWords.length; i++) {
        //   // Set done to true for words up to currentIndex, false for the rest
        //   this.splitTextOnlyWords[i].done = i < this.currentIndex + 1;
        // }
        this.lastFoundWord.next(this.splitTextOnlyWords[this.currentIndex]);
    }

    invokeWordEvent(betweenWord: ITextPortion) {
        // betweenWord.events.forEach((event) => {
        //     // console.log('invoking event', event)
        //     this.formatManagerService.notifyDirectorCommand.next(event as DirectorCommand);
        // })
    }

    start() {
        if (this.speechRecognitionOn) {
            return;
        }
        this.speechRecognitionOn = true;
        this.recognition.start();
        this.recognition.addEventListener('end', (condition) => {
            if (!this.speechRecognitionOn) {
                this.recognition.stop();
                this.currentIndex = 0;
                this.copywriter.setCopy(this.text);
                this.lastFoundWord = new BehaviorSubject<ITextPortion>(null);
                if (this.lastSpokenTime) {
                    this._lastTimeSpoken$.next({ text: '', time: this.lastSpokenTime });
                    this.lastSpokenTime = null;
                }
            } else {
                this.wordConcat();
                /// Restart the service
                try {
                    this.recognition.start();
                } catch (e) {
                    return;
                }
            }
        });
    }

    stop() {
        if (!this.speechRecognitionOn) {
            return;
        }
        this.speechRecognitionOn = false;
        this.firstTimeSpoke = false;

        this.wordConcat();
        this.recognition.stop();
        // console.log("End speech recognition")
    }

    wordConcat() {
        /// here we get the full text when someone stops talking.
        this.speechText = this.speechText + ' ' + this.speechTempWords;

        // next window
        // let slidingWindowArr = this.splitTextOnlyWords.splice(this.currentIndex, this.slidingWindow)
        //
        // for (let i = 0; i < srTextWords.length; i++) {
        // run over the window to look for the word
        // if()
        // }

        this.speechTempWords = '';
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
