import { Injectable } from '@angular/core';
import { PaymentDialogComponent } from '../components/dialogs/plans-and-payments/payment-dialog/payment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsNotifierService, AnalyticsNotifierServices } from './utils/analytics-notifier.service';
import { PaymentType } from './api/plans-api.service';
import { UserStateManagerService } from './state-management/user/user-state-manager.service';
import { IUserPaymentStatus } from './payment/payment-utils.service';

type PaymentDialogOpenedFrom = 'project-planning' | 'studio' | 'user-settings' | 'edit-room' | 'sidenav';

@Injectable({
    providedIn: 'root',
})
export class PaymentDialogService {
    private isPaymentDialogOpen: boolean;
    private paymentStatus: IUserPaymentStatus = null;

    constructor(
        private dialog: MatDialog,
        private analyticsNotifierService: AnalyticsNotifierService,
        private userStateManager: UserStateManagerService
    ) {
        this.userStateManager.paymentStatus$.subscribe((data) => {
            this.paymentStatus = data;
        });
    }

    public async openPaymentDialogAsync(
        toDisableClose: boolean,
        openedFrom: PaymentDialogOpenedFrom
    ): Promise<boolean> {
        if (this.isPaymentDialogOpen) {
            return;
        }
        if (!this.paymentStatus.needToPay || !this.paymentStatus.needToPayNow) {
            return true;
        }
        const featureFlag = this.paymentStatus.plan;
        return new Promise<boolean>((resolve, reject) => {
            let paymentType: PaymentType = 'trial';
            if (featureFlag === 'immediate') {
                paymentType = 'immediately';
            }
            if (this.isPaymentDialogOpen) {
                this.isPaymentDialogOpen = false;
                return resolve(true);
            }
            this.isPaymentDialogOpen = true;

            const dialogRef = this.dialog.open(PaymentDialogComponent, {
                disableClose: toDisableClose,
                panelClass: 'payments-dialog',
                maxHeight: '90vh',
                data: {
                    subscriptionFrom: openedFrom,
                    paymentType: paymentType,
                },
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                this.isPaymentDialogOpen = false; // Reset the flag when dialog closes

                if (result) {
                    //Successful payment
                    this.analyticsNotifierService.notifyEvent('Subscription created', { from: openedFrom }, [
                        AnalyticsNotifierServices.GTM,
                    ]);
                }
                /// Check if the dialog was closed by paying or by clicking on close dialog (result = null)
                return resolve(result);
            });
        });
    }
}
