<div class="wrapper">
    <h3 class="step-title">
        <span>
            We Captured and Learned Your<br />Brand’s Content, Logo and Colors from
            <span class="primary">{{ branding.company?.domain ?? 'your website' }}</span>
        </span>
    </h3>
    <h4 class="step-subtitle">
        <span>Your videos are going to look like this:</span>
    </h4>

    <div class="section">
        <div class="demo-video-wrapper noselect">
<!--            <mat-spinner *ngIf="!lottieConfig"></mat-spinner>-->
          <loading-indicator *ngIf="!lottieReady" size="large"></loading-indicator>
            <dynamic-lottie (animationCreated)="animationCreated($event)" [hidden]="!lottieReady"  [config]="this.lottieConfig" [lottieOptions]="{ autoplay: true }">
            </dynamic-lottie>
<!--          <app-lottie-video-composed-->
<!--            [displayVgControls]="true"-->
<!--            [notifyTime]="false"-->
<!--            [showLoadingIndication]="true"-->
<!--            [special-end-time]="null"-->
<!--            [flipHorizontally]="true"-->
<!--            [playNowOrWait]="true"-->
<!--            [playOrPause]="playOrPause$ | async"-->
<!--            [freezeAtFirstFrame]="false"-->
<!--            [compose-configs]="composedConfig">-->
<!--          </app-lottie-video-composed>-->
        </div>

        <div (click)="switchMode.next(BrandingModeEnum.AUTOMATIC)" class="clickable under-text-link">
            I need to make changes
        </div>
    </div>
</div>
