import { ILayout } from '../defines';
import { IDynamicText, IThumbnailPathAndFrame } from '../stream/old-take-model';
import { IDynamicLottieChange } from 'lottie-json-helper/lib/types';

export interface ILottieJsonMarker {
    cm: string;
    dr: number;
    tm: number;
}

export interface ILottieMarker {
    startTimeInFrames: number;
    durationInFrames: number;
    type: LottieMarkerTypes;
}

export interface IStartLoopMarkers {
    start?: ILottieMarker;
    loop?: ILottieMarker;
}

export interface ILottieSegment {
    lottieSegmentVideoPath: string;
    lottieMark: LottieMarkerTypes;
}

export interface ILottieOutDTO {
    lottieId: string;
    trimStart?: number;
    trimEnd?: number;
}

export interface IBasicLottieLayer extends ILottieOutDTO {
    lottieJsonPath: string;
    startTime?: number;
    endTime?: number;
}

export interface ILottieLayer extends IBasicLottieLayer {
    isRendered?: boolean;
    dynamicValues?: IDynamicText[];
    lottieSegments?: ILottieSegment[];
    lottieThumbnailsAndFrames?: IThumbnailPathAndFrame[];
}

export interface ILottieLayerInDTO extends ILottieLayer {}

export enum LottieMarkerTypes {
    START = 'start',
    LOOP = 'loop',
}

export interface IDynamicLottieData {
    layout: ILayout;
    dynamicLottieChanges: IDynamicLottieChange[];
    basePath: string;
}

interface ILottieBaseAsset {
    id: string;
    [key: string]: any; // Allow additional properties
}

interface ILottieImageAsset extends ILottieBaseAsset {
    w: number;
    h: number;
    u: string;
    p: string;
    e: number;
}
export interface ILottieFont {
    fPath: string;
    fFamily: string;
    fName: string;
    fStyle: string;
    origin: number;
    ascent: number;
    fWeight: number;
}

export interface ILottie {
    assets: ILottieBaseAsset[];
    fonts: {
        list: ILottieFont[];
    } | null;
    markers: ILottieJsonMarker[];
    op: number;
    fr: number;
    h: number;
    w: number;
}
