import { Component } from '@angular/core';
import { OnboardingBrandingModeBase } from '../onboarding-branding-mode-base';
import { BrandingModeEnum } from '../../branding-onboarding.component';

@Component({
    selector: 'onboarding-branding-manual',
    templateUrl: './branding-manual.component.html',
    styleUrl: './branding-manual.component.scss',
})
export class BrandingManualComponent extends OnboardingBrandingModeBase {
    protected readonly BrandingModeEnum = BrandingModeEnum;
}
