import { NgModule } from '@angular/core';

import { OnboardingRoutingModule } from './onboarding-routing.module';
import { OnboardingComponent } from '../../pages/private/onboarding/onboarding.component';
import { OnboardingMobileStepComponent } from '../../components/minor/onboarding-mobile-step/onboarding-mobile-step.component';
import { LoginSuccessComponent } from '../../components/major/auth/login-success/login-success.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material/material.module';
import { BrandingOnboardingComponent } from '../../pages/private/onboarding/branding-onboarding/branding-onboarding.component';
import { BrandingImportResultComponent } from '../../pages/private/onboarding/branding-onboarding/modes/branding-import-result/branding-import-result.component';
import { BrandingAutomaticImportComponent } from '../../pages/private/onboarding/branding-onboarding/modes/branding-automatic-import/branding-automatic-import.component';
import { BrandingManualComponent } from '../../pages/private/onboarding/branding-onboarding/modes/branding-manual/branding-manual.component';
import { BrandingMiniLogoColorsComponent } from '../../pages/private/onboarding/branding-onboarding/modes/branding-mini-logo-colors/branding-mini-logo-colors.component';

@NgModule({
    declarations: [
        OnboardingComponent,
        OnboardingMobileStepComponent,
        LoginSuccessComponent,
        BrandingOnboardingComponent,
        BrandingImportResultComponent,
        BrandingAutomaticImportComponent,
        BrandingManualComponent,
        BrandingMiniLogoColorsComponent,
    ],
    exports: [BrandingOnboardingComponent, BrandingAutomaticImportComponent],
    imports: [SharedModule, MaterialModule, OnboardingRoutingModule],
})
export class OnboardingModule {}
