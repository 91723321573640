<full-screen-loading *ngIf="this.loadingData.isLoading" [text]="this.loadingData.text"></full-screen-loading>

<div class="page-wrapper" [ngClass.lt-sm]="'small-screen'">
    <!-- <div #loadingIndicator class="spinner-holder" *ngIf="loading">
<ng-lottie
[expressCheckoutElementOptions]="{ path: 'assets/animations/loading-scene.json' }"
></ng-lottie>
</div> -->

    <div class="top-arrows" fxHide fxShow.lt-md *ngIf="!this.loadingData.isLoading && steps">
        <div class="left">
            <button mat-icon-button *ngIf="selectedStep > 0" [@fadeInOnEnter] (click)="stepBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="right">
            <button mat-icon-button *ngIf="currentForm?.valid" [@fadeInOnEnter] (click)="tryToProceed(currentForm)">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </div>
    </div>
    <mat-stepper
        style="height: 100vh"
        animationDuration="1000ms"
        [headerPosition]="headerPosition"
        mat-align-tabs="center"
        mat-stretch-tabs="false"
        *ngIf="!this.loadingData.isLoading && steps"
        (selectionChange)="stepChanged()"
        [(selectedIndex)]="selectedStep"
        dynamicHeight
        #stepper
        linear="true">
        <ng-container *ngFor="let configStep of steps; let i = index">
            <mat-step #step label="" *ngIf="!configStep.hideInUI">
                <ng-template matStepContent class="tab-wrapper">
                    <div class="tab-content">
                        <!--     LEFT ARROW       -->
                        <div class="left" fxHide.lt-md>
                            <button mat-icon-button *ngIf="i > 0" [@fadeInOnEnter] (click)="stepBack()">
                                <mat-icon>arrow_back</mat-icon>
                            </button>
                        </div>
                        <!--     Main Part       -->

                        <form class="center" #stepForm="ngForm" class="step-form">
                            <div class="lottie-wrapper" *ngIf="configStep?.topIcon">
                                <ng-lottie
                                    #animatedIcon
                                    class="animated-icon-anim"
                                    containerClass="animated-icon"
                                    [options]="{
                                        path: this.baseCdnUrl + configStep.topIcon,
                                        autoplay: true,
                                        loop: 0,
                                    }"></ng-lottie>
                            </div>

                            <h3
                                class="step-title"
                                *ngIf="configStep?.stepTitle"
                                [innerHTML]="configStep.stepTitle"></h3>
                            <h4 class="step-subtitle" *ngIf="configStep?.stepSubTitle">
                                <u>{{ configStep.stepSubTitle }}</u>
                            </h4>
                            <h5 class="step-description" *ngIf="configStep?.stepDescription">
                                {{ configStep.stepDescription }}
                            </h5>

                            <div *ngIf="configStep?.id === 'branding'">
                                <app-onoarding-branding (finished)="tryToProceed(stepForm)"></app-onoarding-branding>
                            </div>
                            <div
                                *ngFor="let question of configStep.questions; let i = index"
                                #question
                                class="question">
                                <h4 *ngIf="question.text">{{ question.text }}</h4>

                                <!--     TEXT QUESTION       -->
                                <mat-form-field *ngIf="question.type === 'text'">
                                    <mat-label *ngIf="question.innerLabel">{{ question.innerLabel }}</mat-label>
                                    <div>
                                        <input
                                            matInput
                                            type="text"
                                            [(ngModel)]="question.value"
                                            required
                                            [name]="question.id"
                                            pattern="^[A-Za-zéèàùâêîôûëïöüçÉÈÀÙÂÊÎÔÛËÏÖÜÇ]+( [A-Za-zéèàùâêîôûëïöüçÉÈÀÙÂÊÎÔÛËÏÖÜÇ]+)*\s*$"
                                            #questionInput
                                            minlength="2"
                                            maxlength="30" />
                                    </div>
                                    <mat-error
                                        >The length must be between 2 and 30 characters, and it should only include
                                        Latin letters <b>without any numbers</b>.
                                    </mat-error>

                                    <button
                                        *ngIf="question.value"
                                        matSuffix
                                        mat-icon-button
                                        aria-label="Clear"
                                        (click)="question.value = ''">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>

                                <div *ngIf="tokenErrorText" class="token-error">
                                    {{ tokenErrorText }}
                                    <div class="contact-info">
                                        <a href="mailto:support@shuffll.com?subject=Ready to Shuffll In! 🎉"
                                            >To contact us via email, simply click here!</a
                                        >
                                        <div>
                                            Alternatively, you can reach us by sending an email to:
                                            <b class="support-shuffll"> support&#64;shuffll.com </b>
                                        </div>
                                    </div>
                                </div>
                                <!--     RADIO SELECT QUESTION       -->
                                <mat-radio-group
                                    *ngIf="question.type === 'select'"
                                    required
                                    class="options"
                                    [(ngModel)]="question.value"
                                    [name]="question.id">
                                    <mat-radio-button
                                        class="example-radio-button"
                                        color="primary"
                                        *ngFor="let option of question.options"
                                        (change)="tryToProceed(stepForm)"
                                        [value]="option">
                                        <span class="noselect">{{ option }}</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </form>

                        <!--     RIGHT ARROW       -->
                        <div class="right" fxHide.lt-md>
                            <button
                                mat-icon-button
                                *ngIf="stepForm.valid && configStep?.id !== 'branding'"
                                [@fadeInOnEnter]
                                (click)="tryToProceed(stepForm)">
                                <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </mat-step>
        </ng-container>
    </mat-stepper>
</div>
