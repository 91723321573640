import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/guards/authentication.service';
import { UserService } from '../../../../services/api/auth/user.service';
import { PlanEnum, User } from '../../../../models/user';
import { CookieService } from 'src/app/services/utils/cookie.service';
import {
    LOCAL_STORAGE_INITIAL_URL_PARAMS_KEY,
    LOCAL_STORAGE_RETURN_URL_KEY,
} from '../../../../constants/shared/local-storage.constants';

@Component({
    selector: 'app-sign-in-success',
    templateUrl: './login-success.component.html',
    styleUrls: ['./login-success.component.scss'],
})
export class LoginSuccessComponent implements OnInit {
    token: string;
    chosenPlan: PlanEnum | null = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authentication: AuthenticationService,
        private userAPI: UserService,
        private cookieService: CookieService
    ) {}

    /// TODO: All three logins are the same, create 1 component for all
    ngOnInit(): void {
        // Retrieve the current plan from the cookie set on the server-side during the redirect from the payments page to the login page
        const chosenPlanString = this.cookieService.getCookie('chosenPlan');
        // const chosenPlanString = document.cookie;
        if (chosenPlanString) {
            this.chosenPlan = JSON.parse(chosenPlanString) as PlanEnum;
            this.authentication.user.plan = this.chosenPlan;
        } else {
            console.log('No chosen plan found');
        }
        // Take the token from the activated route and set it in the local storage
        // Also, notify that the user is logged-in
        this.activatedRoute.paramMap.subscribe(
            (params: ParamMap) => {
                this.token = params.get('token');
                if (this.token) {
                    this.authentication.login(this.token);

                    // Get user details - name, picture
                    this.userAPI.me().then(
                        (user: User) => {
                            // console.log('me', user);
                            console.log('me', user?.email);
                            if (user) {
                                // let user = new User()
                                // Send user to lead DB and Convertkit
                                this.userAPI.updateDBandConvertkit(user.email, user.name);

                                this.authentication.saveUser(user);
                                // T going back to the previous page by sending the route to the server and passing it
                                //  local storage.

                                let returnUrl = localStorage.getItem(LOCAL_STORAGE_RETURN_URL_KEY);
                                // Get extra query params
                                const initialUrlParamsStr = localStorage.getItem(LOCAL_STORAGE_INITIAL_URL_PARAMS_KEY);

                                let initialUrlParams = {};
                                if (initialUrlParamsStr) {
                                    // Try to parse safely
                                    try {
                                        initialUrlParams = JSON.parse(initialUrlParamsStr);
                                    } catch (error) {
                                        console.error('Error parsing initial url params:', error);
                                    }
                                }

                                if (returnUrl) {
                                    // Go to main dashboard
                                    if (returnUrl.includes('dashboard')) {
                                        returnUrl = '/dashboard';
                                    }
                                    // Avoid rerouting to the login success
                                    if (returnUrl.includes('onboarding/authenticate')) {
                                        returnUrl = '/dashboard';
                                    }
                                    // console.log('routing to ' + returnUrl);

                                    console.log('extraParams ', initialUrlParams);
                                    this.router.navigate([decodeURI(returnUrl)], {
                                        queryParams: initialUrlParams,
                                    });
                                } else {
                                    console.log('routing to feed - no returnUrl');
                                    this.router.navigate(['/dashboard']);
                                }
                            }
                        },
                        (error) => {
                            // Error occoured retreiving user
                            console.log('Error occoured retreiving user');
                            this.router.navigate(['/']);
                        }
                    );
                }
                // console.log(this.token);
            },
            (error) => {
                console.log('could not get token');
            }
        );
    }
}
