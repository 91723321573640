import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AnimationOptions, BaseDirective } from 'ngx-lottie';
import { ArtDirectorService } from '../../services/art-director.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { IDynamicLottieData, ILottie, ILottieFont } from 'src/app/models/lottie/lottie-defines';
import { FontsStoreService } from '../../services/state-management/configs/fonts-store.service';

@Component({
    selector: 'dynamic-lottie',
    template: `
        <div
            #container
            [style.width]="width || '100%'"
            [style.height]="height || '100%'"
            [ngStyle]="styles"
            [ngClass]="containerClass"></div>

        <!-- Hidden div to preload fonts -->
        <div id="font-preload" style="visibility: hidden; position: absolute; top: -9999px;"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./dynamic-lottie.component.scss'],
})
export class DynamicLottieComponent extends BaseDirective implements OnChanges {
    @Input() width: string | null = null;
    @Input() height: string | null = null;
    @Input() styles: Partial<CSSStyleDeclaration> | null;
    @Input() containerClass: string | null = null;

    @ViewChild('container', { static: true }) container: ElementRef<HTMLElement> = null!;

    @Input('config') config: IDynamicLottieData;
    @Input('lottieOptions') lottieOptions: AnimationOptions;
    @Input('lottieIconPath') lottieIconPath: string;

    /**
     * Looping the loop marker if existed, if not freezing at the end of the animation.
     */

    constructor(
        private artDirectorService: ArtDirectorService,
        private configurationService: ConfigurationService,
        private fontStore: FontsStoreService
    ) {
        super();
        //If there is a case of lottie icon for card for example we use it and dont need all the configs
    }

    private async loadAnimationAsync(changes: SimpleChanges) {
        try {
            const dynamicAsset = await this.artDirectorService.getBaseJsonAsync(
                this.config.layout,
                this.config.basePath
            );
            const lottieOriginalString = dynamicAsset.content;

            const original = JSON.parse(lottieOriginalString);
            let replacement: ILottie;
            try {
                replacement = JSON.parse(
                    this.artDirectorService.replaceDynamicValues(
                        this.config.dynamicLottieChanges,
                        dynamicAsset.dynamics,
                        lottieOriginalString
                    )
                );
            } catch (e) {
                console.warn('Could not replace lottie values', e);
            }

            // ** Inject fonts dynamically **
            if (replacement?.fonts?.list) {
                this.injectFonts(replacement.fonts.list);
            }

            // ** Wait for fonts to load before playing animation **
            await this.waitForFontsAsync();

            const options: AnimationOptions = {
                ...(this.lottieOptions ?? {}),
                animationData: replacement ?? original,
                assetsPath: `${this.configurationService.baseCdnUrl}`,
            };

            changes['options'] = {
                previousValue: undefined,
                currentValue: options,
                firstChange: true,
                isFirstChange: () => true,
            };
            super.loadAnimation(changes, this.container.nativeElement);
            // Use arrow function to retain `this` context
        } catch (loadError) {
            console.error(`An error occurred while trying to load lottie animation. Error:`, loadError);
        }
    }

    /**
     * Injects font styles into the document head and adds hidden spans to force rendering.
     */
    private injectFonts(fonts: ILottieFont[]): void {
        const fontPreloadDiv = document.getElementById('font-preload');
        if (!fontPreloadDiv) {
            return;
        }

        fonts.forEach((font, index) => {
            if (!font?.fPath || !font.fFamily) {
                return;
            } // Skip invalid fonts

            // Generate a unique key for each font
            const fontKey = `${font.fFamily}-${font.fWeight}-${font.fStyle}`;

            // Skip if already loaded
            if (this.fontStore.loadedFonts.has(fontKey)) {
                return;
            }
            this.fontStore.loadedFonts.add(fontKey); // Mark font as loaded

            // Inject font stylesheet link
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = font.fPath;
            link.crossOrigin = 'anonymous';
            document.head.appendChild(link);

            // Inject hidden span to force font rendering
            const span = document.createElement('span');
            span.className = `font-loader-${index}`;
            span.style.fontFamily = `'${font.fFamily}', sans-serif`;
            span.style.fontWeight = font.fWeight.toString();
            span.style.fontStyle = font.fStyle || 'normal';
            span.style.fontSize = '20px';
            span.innerText = 'Loading...';
            fontPreloadDiv.appendChild(span);
        });
    }

    /**
     * Waits for all fonts to be loaded before rendering the animation.
     */
    private async waitForFontsAsync(timeout = 5000): Promise<void> {
        return new Promise((resolve) => {
            if (!document.fonts) {
                console.warn('No Font API available in this browser.');
                return resolve();
            }

            const timer = setTimeout(() => {
                console.warn('Fonts did not load within timeout.');
                resolve();
            }, timeout);

            document.fonts.ready
                .then(() => {
                    clearTimeout(timer);
                    resolve();
                })
                .catch(() => {
                    clearTimeout(timer);
                    console.error('Error while waiting for fonts.');
                    resolve();
                });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['config']) {
            if (!this.config) {
                return;
            }
            this.loadAnimationAsync(changes);
        }
    }
}
