<div class="wrapper">
    <h3 class="step-title">
        <span>Upload your logo and colors manually </span>
    </h3>
    <h4 class="step-subtitle">
     <span>Or use our auto-import feature to do it for you and pull content <br /> from your website for personalized video projects</span>
    </h4>

    <div class="section">
        <onboarding-branding-mini-logo-colors (updateBranding)="updateBranding.next($event)"
          [branding]="branding">
        </onboarding-branding-mini-logo-colors>
    </div>

    <div (click)="switchMode.next(BrandingModeEnum.AUTOMATIC)" class="clickable under-text-link">
        Import from my website
    </div>
</div>
