<div class="padded minimized page-wrapper" autosize *ngIf="branding && brandingMode">
    <div class="container">
        <div class="row-centered">
            <onboarding-branding-import-result
                *ngIf="brandingMode === BrandingModes.IMPORTED"
                (switchMode)="switchMode($event)"
                (updateBranding)="updateBranding($event)"
                [branding]="branding"></onboarding-branding-import-result>
            <onboarding-branding-manual
                *ngIf="brandingMode === BrandingModes.MANUAL"
                (switchMode)="switchMode($event)"
                (updateBranding)="updateBranding($event)"
                [branding]="branding"></onboarding-branding-manual>

            <onboarding-branding-automatic-import
                *ngIf="brandingMode === BrandingModes.AUTOMATIC"
                (switchMode)="switchMode($event)"
                (updateBranding)="updateBranding($event)"
                [branding]="branding"></onboarding-branding-automatic-import>
        </div>
    </div>
    <div
        class="finish-button-wrapper"
        *ngIf="
            brandingMode !== BrandingModes.IMPORTED &&
            !(this.branding.logo && !this.onboardingService.isPlaceholderLogo)
        ">
        <div class="clickable under-text-link" (click)="finishButtonClicked()">I'll do it later</div>
    </div>
    <div
        class="finish-button-wrapper"
        *ngIf="
            brandingMode === BrandingModes.IMPORTED || (this.branding.logo && !this.onboardingService.isPlaceholderLogo)
        ">
        <button class="flex-button row finish-button" mat-raised-button color="primary" (click)="finishButtonClicked()">
            Finish
        </button>
    </div>
</div>
<div *ngIf="!branding" class="loading-spinner-container">
    <mat-spinner class="loading-spinner"></mat-spinner>
</div>
