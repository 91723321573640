import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User, UserVideoIdeaStatusEnum } from '../../../models/user';
import { ProfileService } from '../../show/profile.service';
import { IUserPaymentStatus, PaymentUtilsService } from '../../payment/payment-utils.service';

@Injectable({
    providedIn: 'root',
})
export class UserStateManagerService {
    private _userVideoIdeasUpdated$ = new Subject<UserVideoIdeaStatusEnum>();
    public userVideoIdeasUpdate$ = this._userVideoIdeasUpdated$.asObservable();

    private _paymentStatus$ = new BehaviorSubject<IUserPaymentStatus>({
        needToPayNow: false,
        plan: null,
        needToPay: false,
    });
    public paymentStatus$ = this._paymentStatus$.asObservable();

    constructor(
        private profileService: ProfileService,
        private paymentUtilsService: PaymentUtilsService
    ) {
        this.profileService.user$.subscribe((user) => {
            this.setUserNeedsToPayAsync(user);
        });
    }

    userVideoIdeasStatusUpdate(status: UserVideoIdeaStatusEnum) {
        this._userVideoIdeasUpdated$.next(status);
    }

    async setUserNeedsToPayAsync(user: User) {
        if (!user) {
            return;
        }
        const doesUserNeedsToPay = await this.checkIfUserNeedsToPayAsync(user);
        this._paymentStatus$.next(doesUserNeedsToPay);
    }

    async checkIfUserNeedsToPayAsync(user: User) {
        try {
            const doesUserNeedsToPay = await this.paymentUtilsService.checkIfUserNeedsToPayAsync(user);
            return doesUserNeedsToPay;
        } catch (error) {
            return null;
        }
    }

    getNeedToPayValue() {
        return this._paymentStatus$.value;
    }
}
