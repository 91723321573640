import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { User } from 'src/app/models/user';

@Injectable({
    providedIn: 'root',
})
export class PathHelperService {
    constructor(private config: ConfigurationService) {}

    getFormatBaseAssetsPath(formatBasePath: string) {
        return `${formatBasePath}/assets`;
    }

    public getUserProfileImage(user: Partial<User>) {
        if (!user?.profileImgUrl) {
            const firstLetter = (user.name ?? user.email).substring(0, 1).toLowerCase();
            return `${this.config.baseCdnUrl}/files/platform/letters/${firstLetter}.svg`;
        }
        return user.profileImgUrl;
    }
}
