import { Injectable } from '@angular/core';
import { AuthenticationService } from '../guards/authentication.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { INewProjectRequest } from '../api/auth/projects/project-auth-api.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationHelperService {
    constructor(
        public router: Router,
        public authentication: AuthenticationService,
        private location: Location
    ) {}

    jumpToHost() {
        // Jump to sign-in screen if not logged in
        this.router.navigate(['/dashboard']);
    }

    goBack() {
        this.location.back();
    }

    navigeteToWizard(
        wizardUrlParams: Partial<INewProjectRequest>,
        prodType: 'videoTypes' | 'videoIdea' | 'createProjectButton' | 'template'
    ): void {
        const queryParams = {
            ...wizardUrlParams,
            prodType,
        };
        this.router
            .navigate(['/dashboard', { outlets: { panel: ['new'] } }], {
                queryParams,
            })
            .catch((error) => {
                console.error('Navigation error:', error);
            });
    }
}
