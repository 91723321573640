import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { OnboardingBrandingModeBase } from '../onboarding-branding-mode-base';
import { BrandingModeEnum } from '../../branding-onboarding.component';
import { IDynamicLottieData } from '../../../../../../models/lottie/lottie-defines';
import { v4 as uuidv4 } from 'uuid';
import { IFakeDetection, ILayout, MimeTypeEnum } from 'src/app/models/defines';
import { OnboardingService } from '../../../../../../services/onboarding.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { ArtDirectorService } from '../../../../../../services/art-director.service';
import { IComposedEditTakeConfigs } from '../../../../../../models/job/editor-defines';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'onboarding-branding-import-result',
    templateUrl: './branding-import-result.component.html',
    styleUrl: './branding-import-result.component.scss',
})
export class BrandingImportResultComponent extends OnboardingBrandingModeBase implements OnChanges, OnInit, OnDestroy {
    protected readonly BrandingModeEnum = BrandingModeEnum;
    lottieConfig: IDynamicLottieData;
    composedConfig: IComposedEditTakeConfigs;
    demoVideoLottie: string;
    LOTTIE_DEMO_URL: string = '/files/platform/onboarding/user-branding-preview/user-branding-preview.json';
    VIDEO_DEMO_URL: string = '/files/platform/onboarding/user-branding-preview/assets/video-insert.webm';
    playOrPause$ = new BehaviorSubject<IFakeDetection>({
        isPlaying: false,
        randomNumber: Math.random(),
    });
    lottieReady = false;

    constructor(
        onboardingService: OnboardingService,
        private conifg: ConfigurationService,
        private artDirector: ArtDirectorService
    ) {
        super(onboardingService);
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['branding']) {
            // only if the change is in branding
            if (this.branding) {
                const lottieChanges = this.artDirector.extractDynamicLottieByUserBranding(null, this.branding);
                const lottieLayout: ILayout = {
                    _id: uuidv4(),
                    lottiePath: this.LOTTIE_DEMO_URL,
                };
                this.lottieConfig = {
                    dynamicLottieChanges: lottieChanges,
                    layout: lottieLayout,
                    basePath: '',
                };

                this.composedConfig = {
                    id: uuidv4(),
                    videoLottieConfigs: [
                        {
                            id: uuidv4(),
                            lottieData: this.lottieConfig,
                            basicLottieLayersOnTrack: [],
                            videoLayers: [
                                {
                                    id: 'dummy',
                                    videoPosition: {
                                        id: 'dummy',
                                        width: 100,
                                        height: 100,
                                        y: 0,
                                        x: 0,
                                        position: 0,
                                    },
                                    width: 100,
                                    height: 100,
                                    uploadPath: this.VIDEO_DEMO_URL,
                                    storeManagerId: 'branding-onboarding',
                                    uinuqeId: 'dummy',
                                },
                            ],
                        },
                    ],
                };
            }
        }
    }

    animationCreated(event) {
        this.lottieReady = true;
    }

    ngOnDestroy(): void {
        this.lottieReady = false;
    }
}
