import { Component, Input, OnInit, Output } from '@angular/core';
import { IBranding } from '../../../../../models/user';
import { BrandingModeEnum, IUpdateBrandingEvent } from '../branding-onboarding.component';
import { OnboardingService } from '../../../../../services/onboarding.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    template: '',
})
export abstract class OnboardingBrandingModeBase implements OnInit {
    @Input() branding: IBranding;
    @Output() switchMode: BehaviorSubject<BrandingModeEnum> = new BehaviorSubject<BrandingModeEnum>(null);
    @Output() updateBranding: BehaviorSubject<IUpdateBrandingEvent> = new BehaviorSubject<IUpdateBrandingEvent>(null);

    constructor(protected onboardingService: OnboardingService) {}

    ngOnInit(): void {}
}
