<div class="container row-centered logo-and-colors big-gapped">
    <div class="row row-centered gapped">
        <div class="logo-col">
            <app-file-drop
                [accepts]="['.svg', '.png', '.jpg', '.jpeg']"
                [uploadAPI]="brandingAPI.uploadLogo"
                [extraData]="{ updateBothLogos: true }"
                [hideUpload]="true"
                [image]="logo"
                [text]="'Upload Your Logo'"
                [tooltipText]="'Click to upload logo'"
                [minifiedImageUpload]="true"
                (uploadFinished)="uploadingLogo()"></app-file-drop>
        </div>
    </div>
    <div class="pickers column-centered gapped" *ngIf="branding?.colors">
        <div class="column-centered"    [matTooltip]="'Pick Primary Color'">
            Primary Color
            <div
                class="color-picker-container"
                [(colorPicker)]="branding.colors.colorPrimary"
                [style.background]="branding.colors.colorPrimary"
                (colorPickerSelect)="singleColorChange($event)"
                [cpOutputFormat]="'hex'"
                [cpDialogDisplay]="'popup'"
             ></div>
        </div>
        <div class="column-centered"  [matTooltip]="'Pick Secondary Color'">
            Secondary Color
            <div
                class="color-picker-container"
                [(colorPicker)]="branding.colors.colorSecondary"
                (colorPickerSelect)="singleColorChange($event)"
                [style.background]="branding.colors.colorSecondary"
                [cpOutputFormat]="'hex'"
                [cpDialogDisplay]="'popup'"


               ></div>
        </div>
        <!--        <div-->
        <!--            class="color-picker-container"-->
        <!--            [(colorPicker)]="branding.colors.colorExtra"-->
        <!--            (colorPickerSelect)="singleColorChange($event)"-->
        <!--            [style.background]="branding.colors.colorExtra"></div>-->
    </div>
</div>
