import { MissingArgumentsError } from 'src/app/models/errors/general.errors';
import { PROJECT_BASE_URL } from './project.urls';

const SCENES_URL = '/scenes';

export function getProjectSpecificSceneUrl(projectId: string, sceneId: string) {
    if (!projectId || !sceneId) {
        throw new MissingArgumentsError(
            `Could not get specific scene url because one of the arguments is null is null`
        );
    }

    const generalSceneUrl = getRecordingScenesGeneralUrl(projectId);
    return `${generalSceneUrl}/${sceneId}`;
}

export function getRecordingScenesGeneralUrl(projectId: string): string {
    if (!projectId) {
        throw new Error(`Could not get recording scene url because recording id is null`);
    }
    return `${PROJECT_BASE_URL}/${projectId}${SCENES_URL}`;
}

export function updateSceneCompositionPUTUrl(projectId: string, sceneId: string) {
    const sceneDetailsUrl = getProjectSpecificSceneUrl(projectId, sceneId);
    return `${sceneDetailsUrl}/composition`;
}
