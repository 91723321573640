<div class="sidenav-menu" [ngClass]="(_isOpen | async) ? 'menu-opened' : 'menu-closed'">
    <div class="sidenav-top">
        <div class="logo-and-collapse">
            <div class="sidenav-logo" *ngIf="_isOpen | async"></div>
            <button
                mat-icon-button
                [matTooltip]="(_isOpen | async) ? 'Minimize Menu' : 'Expand Menu'"
                (click)="toggleIsOpen()">
                <mat-icon [ngClass]="(_isOpen | async) ? 'icon-normal' : 'icon-rotated'">menu</mat-icon>
            </button>
        </div>
        <!--
    <button
      *ngIf="this.isShuffllUser"
      mat-button
      class="sidenav-menu-invoice"
      routerLink="/host"
      [routerLinkActive]="'active'"
      style="background: grey; color: white">
      <mat-icon>videocam</mat-icon>
      <span *ngIf="_isOpen | async">Quick Production</span>
    </button> -->

        <button
            [matTooltip]="(_isOpen | async) ? null : 'Home'"
            mat-button
            class="sidenav-menu-item"
            [routerLink]="[{ outlets: { panel: ['home'] } }]"
            [routerLinkActive]="'active'">
            <mat-icon>home</mat-icon>
            <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Home</span>
        </button>

        <mat-divider></mat-divider>
        <button
            [matTooltip]="(_isOpen | async) ? null : 'My Projects'"
            mat-button
            class="sidenav-menu-item"
            [routerLink]="[{ outlets: { panel: ['library'] } }]"
            [routerLinkActive]="'active'">
            <mat-icon>apps</mat-icon>
            <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>My Projects</span>
        </button>
        <button
            [matTooltip]="(_isOpen | async) ? null : 'Branding'"
            mat-button
            class="sidenav-menu-item"
            [routerLink]="[{ outlets: { panel: ['branding'] } }]"
            routerLinkActive="active">
            <mat-icon>brush</mat-icon>
            <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Branding</span>
        </button>
        <button
            [matTooltip]="(_isOpen | async) ? null : 'Assets'"
            mat-button
            class="sidenav-menu-item"
            [routerLink]="[{ outlets: { panel: ['assets'] } }]"
            routerLinkActive="active">
            <mat-icon>perm_media</mat-icon>
            <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Assets</span>
        </button>
        <button
            [matTooltip]="(_isOpen | async) ? null : 'Launch Code'"
            *ngIf="isShuffllUser"
            mat-button
            class="sidenav-menu-item"
            [routerLink]="[{ outlets: { panel: ['launch-project'] } }]"
            routerLinkActive="active">
            <mat-icon>rocket_launch</mat-icon>
            <span *ngIf="_isOpen | async" fxHide fxShow.gt-xs>Launch Code</span>
        </button>
    </div>
    <span *ngIf="!this.config.isProduction" [matTooltip]="this.config.version" class="version">
        {{ this.config.environment }}
        v{{ this.config.version }}
    </span>

    <!--    <button-->
    <!--            mat-button-->
    <!--            class="sidenav-menu-item"-->
    <!--            [ngStyle]="{-->
    <!--      background: toOpenWalkthrough ? 'gray' : 'none',-->
    <!--      width: toOpenWalkthrough && !_isOpen ? '150px' : 'none'-->
    <!--    }"-->
    <!--            (click)="openWalkthrough()">-->
    <!--        <div class="walkthrough-side-nav">-->
    <!--            <img-->
    <!--                    class="rocket-icon"-->
    <!--                    src="\assets\images\icons\walkthrough\rocket.png"/>-->
    <!--            <span>Get Started</span>-->
    <!--        </div>-->
    <!--    </button>-->

    <div class="sidenav-bottom">
        <mat-divider></mat-divider>

        <button
            mat-button
            class="sidenav-menu-item active feedback-button"
            appFeedbackPopupButton
            [width]="'500px'"
            [minHeight]="'560px'"
            [maxWidth]="'95vw'"
            [disableClose]="true"
            [autoFocus]="false"
            [restoreFocus]="true"
            [panelClass]="'custom-dialog-class'"
            [hasBackdrop]="true"
            [backdropClass]="'cdk-overlay-dark-backdrop'"
            [data]="{ feedbackFormId: FeedbackEnum.GENERAL_FEEDBACK }"
            [matTooltip]="(_isOpen | async) ? null : 'Give Feedback'"
            [routerLinkActive]="'active'">
            <mat-icon>thumb_up_alt</mat-icon>
            <span *ngIf="(_isOpen | async) && !isMobileUser" class="margin-lable">Feedback</span>
        </button>
        <button
            *ngIf="(this.userStateManagerService.paymentStatus$ | async)?.needToPayNow"
            mat-button
            class="sidenav-menu-item active upgrade-button"
            [featureFlag]="{
                features: ['should_see_paywall'],
                featureFlagLogic: 'AND',
                featureFlagMode: 'ngIf',
                negativeLogic: false,
            }"
            (click)="openPaymentsDialog()">
            <mat-icon>bolt</mat-icon>
            <span *ngIf="_isOpen | async" class="margin-lable">Upgrade</span>
        </button>
        <button
            mat-button
            class="sidenav-menu-item flex row flex-button profile-button"
            [matMenuTriggerFor]="userMenu"
            style="min-height: 60px">
            <div
                id="nav-user"
                *ngIf="authentication.isAuthenticated$ | async"
                [ngStyle]="{
                    'background-image': 'url(\'' + profilePic + '\')',
                }"></div>
            <div id="nav-name" class="align-left" *ngIf="authentication.isAuthenticated$ | async">
                <div *ngIf="_isOpen | async" fxHide fxShow.gt-xs class="bold name">
                    {{ this.user.name }}
                </div>
                <div class="email" *ngIf="_isOpen | async" fxHide fxShow.gt-xs>
                    {{ this.user.email }}
                </div>
            </div>
        </button>
        <mat-menu #userMenu="matMenu">
            <button
                mat-menu-item
                class="user-menu-item"
                [routerLink]="[{ outlets: { panel: ['settings'] } }]"
                [routerLinkActive]="'active'">
                <mat-icon>account_box</mat-icon>
                Account
            </button>
            <button
                mat-menu-item
                class="user-menu-item"
                (click)="goToAdmin()"
                [routerLinkActive]="'active'"
                *ngIf="isShuffllUser">
                <mat-icon>admin_panel_settings</mat-icon>
                Admin
            </button>
            <!-- <button
  mat-menu-invoice
  [routerLinkActive]="'active'"
  (click)="openHelp()"
  class="user-menu-invoice"
>
  <mat-icon>help</mat-icon>
  How To
</button> -->

            <button mat-menu-item class="user-menu-item" (click)="openSupport()" [routerLinkActive]="'active'">
                <mat-icon>contact_support</mat-icon>
                Support
            </button>
            <button
                mat-menu-item
                class="user-menu-item"
                (click)="authentication.logout('/dashboard'); gtmService.notifyEvent('Navbar - Log Out')"
                [routerLinkActive]="'active'">
                <mat-icon>logout</mat-icon>
                Log Out
            </button>
        </mat-menu>
    </div>
</div>
