<div class="wrapper">
    <div *ngIf="this.feedbackForm" mat-dialog-title>
        <h1 [innerHTML]="this.feedbackForm?.feedbackTitle || 'Feedback Time'"></h1>
        <h4 *ngIf="this.feedbackForm?.feedbackDescription" [innerHTML]="this.feedbackForm?.feedbackDescription"></h4>
    </div>

    <div class="questions-contaiener" mat-dialog-content>
        <div class="question" *ngFor="let question of this.feedbackForm?.questions">
            <div class="question-text" [innerHTML]="question.text"></div>

            <!-- Badge Select -->
            <mat-chip-listbox *ngIf="question.type === questionTypeEnum.BADGE_SELECT" [(ngModel)]="question.value">
                <mat-chip-option *ngFor="let option of question.options">{{ option }}</mat-chip-option>
            </mat-chip-listbox>

            <!-- Text -->
            <textarea
                *ngIf="question.type === questionTypeEnum.TEXT"
                style="width: 100%; height: 300px"
                matInput
                type="text"
                [(ngModel)]="question.value"
                cdkTextareaAutosize
                placeholder="Type your answer"></textarea>

            <!-- Select -->
            <!-- Checkboxes -->
            <div *ngIf="question.type === questionTypeEnum.SELECT || question.type === questionTypeEnum.MULTI_SELECT">
                <div *ngFor="let option of question.options">
                    <mat-checkbox
                        color="primary"
                        [checked]="checkboxState[question.id]?.[option] || false"
                        (change)="onCheckboxChange(question, option, $event)">
                        {{ option }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="closeDialog(false)">Close</button>
        <button mat-raised-button color="primary" (click)="saveUserAnswers()">Send</button>
    </div>
</div>
