<div class="wrapper" [ngClass]="{'branding-wrapper': isFromBranding}">
    <h3 class="step-title">
        <span *ngIf="!isFromHomePage"
            >Enter Your Company's URL – We’ll Capture Your<br />Brand’s Content, Colors, and Logo
        </span>
        <span *ngIf="isFromHomePage"
            >Enter your website to generate<br />
            personalized video ideas
        </span>
    </h3>

    <form [formGroup]="domainForm" (submit)="saveCompanyWebsite()">
        <div class="section">
            <mat-form-field class="website-field" style="height: 75px" appearance="outline">
                <input
                    placeholder="Enter your homepage"
                    matInput
                    formControlName="companyWebsite"
                    type="text"
                    (keydown.enter)="saveCompanyWebsite()"
                    (focusout)="saveCompanyWebsite()"
                    [required]="true" />
                <mat-error *ngIf="domainForm.get('companyWebsite')?.errors?.pattern">
                    Please enter a valid website.
                </mat-error>
                <button
                    matSuffix
                    class="flex-button row import-button"
                    mat-raised-button
                    color="primary"
                    (click)="importBrandingByDomain()"
                    [disabled]="!domainForm.get('companyWebsite')?.valid || importing">
                    {{ importing ? 'Importing' : 'Import' }}
                    <mat-spinner class="white-spinner" *ngIf="importing"></mat-spinner>
                </button>
            </mat-form-field>
            <div
                *ngIf="!isFromBranding && !isFromHomePage"
                (click)="switchMode.next(BrandingModeEnum.MANUAL)"
                class="clickable under-text-link">
                I'll upload manually instead
            </div>
        </div>
    </form>
</div>
