import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IColorPalette } from '../../../../services/utils/color-palettes.service';
import { BrandingAuthApiService } from '../../../../services/api/auth/branding-auth-api.service';
import { IBranding } from '../../../../models/user';
import { ProfileService } from '../../../../services/show/profile.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { OnboardingService } from '../../../../services/onboarding.service';
import {
    ConfirmationModalComponent,
    IConfirmationModalData,
} from '../../../../components/dialogs/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { IDefaultLaunchBrand, ILaunchStepEnum } from '../../../../models/defines';
import { Subject, takeUntil } from 'rxjs';

export enum BrandingModeEnum {
    IMPORTED = 'imported',
    AUTOMATIC = 'automatic',
    MANUAL = 'manual',
}
export interface IUpdateBrandingEvent {
    property: string;
    updateMessage: string;
}
@Component({
    selector: 'app-onoarding-branding',
    templateUrl: './branding-onboarding.component.html',
    styleUrls: ['./branding-onboarding.component.scss'],
})
export class BrandingOnboardingComponent implements OnInit, OnDestroy {
    branding: IBranding;

    @Output()
    finished: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    extractBrandingError = new EventEmitter<number>(false);

    @Input({ alias: 'defaultBrand' })
    defaultBrand: IDefaultLaunchBrand;

    @Input()
    brandInit = false;

    // branding: IBranding;
    uplaodLogoMode = false;
    displayWaterMark = true;
    assetBasePath: string;
    selectedColorPallete: IColorPalette;

    brandingMode: BrandingModeEnum = null;
    BrandingModes = BrandingModeEnum;
    importing = false;

    companyAbout = '';

    onDestroy$ = new Subject<boolean>();
    importedManually: boolean = false;

    constructor(
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private _snackBar: MatSnackBar,
        public brandingAPI: BrandingAuthApiService,
        public profile: ProfileService,
        private config: ConfigurationService,
        public onboardingService: OnboardingService
    ) {
        this.assetBasePath = this.config.baseCdnUrl;
    }

    // newColorSelected() {
    //     const newPallet = this.collorPalleteFormControl.value;
    //
    //     console.log('New Colors', newPallet);
    //     if (newPallet) {
    //         this.branding.colors = newPallet.value;
    //         this.updateBranding('colors');
    //     }
    // }

    public updateBranding(eventDetails: IUpdateBrandingEvent) {
        /// We need to avoid sending multiple updates simultaniusly
        // this.loadingData = true;
        if (!eventDetails?.property) {
            return;
        }
        console.log('Update branding', eventDetails.property);
        console.log('Update branding', this.branding[eventDetails.property]);

        this.brandingAPI
            .updateProperty(eventDetails.property, this.branding[eventDetails.property])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((x) => {
                this.profile.updateUserBranding(this.branding);
                if (eventDetails.updateMessage) {
                    this._snackBar.open(eventDetails.updateMessage, 'Dismiss', { duration: 3000 });
                }

                // this.loadingData = false;
            });
    }

    saveCompanyStory() {
        this.branding.company = { name: this.companyAbout };
        this.updateBranding({ property: 'about', updateMessage: 'Brand Story Updated' });
    }

    switchMode(mode: BrandingModeEnum) {
        if (mode) {
            this.brandingMode = mode;
        }
    }

    public loadBranding(branding: IBranding, updateToLogoColors = false) {
        if (branding) {
            console.log('branding', branding);
            this.branding = JSON.parse(JSON.stringify(branding));
            this.importing = false;
            this.companyAbout = this.branding?.about ?? '';

            /// Update the user as well
        }
        // this.loadingData = false;
    }

    ngOnInit(): void {
        this.onboardingService.branding.pipe(takeUntil(this.onDestroy$)).subscribe((brandingState) => {
            console.log('branding 1', brandingState?.branding);
            if (brandingState?.branding) {
                /// Check if this the initial brand we got and not manually initiated
                if (
                    !this.importedManually &&
                    brandingState.branding.logo &&
                    brandingState.branding.colors?.colorPrimary &&
                    !brandingState.branding.isDefault
                ) {
                    this.brandingMode = BrandingModeEnum.IMPORTED;
                } else {
                    // Todo: we might need to present an error message here
                    this.brandingMode = BrandingModeEnum.AUTOMATIC;
                }

                this.loadBranding(brandingState.branding, true);
            }
        });

        this.onboardingService.noLogoNotifyOnboarding
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((moveToUploadLogo: boolean) => {
                if (moveToUploadLogo) {
                    this.brandingMode = BrandingModeEnum.MANUAL;
                    return;
                }
                this.finishButtonClicked(false);
            });
    }

    newDesignSelected() {
        this.updateBranding({ property: 'design', updateMessage: null });
    }

    finishButtonClicked(showDialog = true) {
        if ((this.branding.logo && !this.onboardingService.isPlaceholderLogo) || !showDialog) {
            this.finished.emit(true);
            return;
        }
        const confirmationDialogData: IConfirmationModalData = {
            title: {
                htmlContent: this.sanitizer.bypassSecurityTrustHtml(`😶‍🌫️ Don't Miss Out on Personalizing Your Videos!`),
                align: 'center',
                css: {
                    color: '',
                    style: {},
                },
            },
            body: [
                {
                    htmlContent: this.sanitizer.bypassSecurityTrustHtml(
                        `Adding your website or logo can make your videos uniquely yours and boost your brand's impact. Proceed without it?`
                    ),
                    align: 'center',
                    css: {
                        color: '',
                        style: {},
                    },
                },
            ],
            buttons: [
                {
                    text: `Skip`,
                    css: {
                        color: 'white',
                        style: {},
                    },
                    callback: async () => {
                        // const launchBranding: ILaunchBranding = {
                        //   companyUrl: this.branding.company.domain,
                        //   logo: this.branding.logo,
                        //   colors: this.branding.colors
                        // };
                        // this.launchBrandingAnswers.emit(launchBranding);
                        this.finished.emit(true);
                    },
                },
                {
                    text: 'Add Branding',
                    css: {
                        color: 'primary',
                        style: {},
                    },
                    callback: () => {},
                },
            ],
            input: null,
        };
        this.dialog.open(ConfirmationModalComponent, {
            width: '550px',
            maxWidth: '90vw',
            data: confirmationDialogData,
        });
    }

    protected readonly launchStepEnum = ILaunchStepEnum;

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }
}
