import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ILayout, MimeTypeEnum } from 'src/app/models/defines';
import { HttpErrorResponse } from '@angular/common/http';
import { IDesignGroup, IExtendedDesignGroup } from '../models/design.model';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class FunctionsHelperService {
    constructor(private sanitizer: DomSanitizer) {}

    public blobToArrayBufferAsync(blob: Blob): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.result instanceof ArrayBuffer) {
                    resolve(reader.result);
                } else {
                    reject(new Error('Failed to convert Blob to ArrayBuffer'));
                }
            };

            reader.onerror = () => {
                reject(reader.error);
            };

            reader.readAsArrayBuffer(blob);
        });
    }

    public transformLocalUrlToSafe(url: string): string {
        return this.sanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this.sanitizer.bypassSecurityTrustResourceUrl(url)
        );
    }

    public deepClone<T>(source: T): T {
        return JSON.parse(JSON.stringify(source));
    }

    // Assuming 'arrayBuffer' is your ArrayBuffer
    public arrayBufferToBlob(arrayBuffer: ArrayBuffer, type: 'video/mp4' | 'audio/mp3'): Blob {
        return new Blob([arrayBuffer], { type: type });
    }

    public timeAgo(date: number): string {
        const now = new Date();
        const past = new Date(date);
        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const daysAgo = Math.floor((now.getTime() - past.getTime()) / millisecondsPerDay);
        const weeksAgo = Math.floor(daysAgo / 7);
        const monthsAgo = Math.floor(daysAgo / 30); // Approximate number of days in a month
        const yearsAgo = Math.floor(daysAgo / 365); // Approximate number of days in a year

        // Handle different time spans
        if (daysAgo === 0) {
            return 'Today';
        } else if (daysAgo === 1) {
            return 'Yesterday';
        } else if (daysAgo < 7) {
            return `${daysAgo} days ago`;
        } else if (weeksAgo < 4) {
            return `${weeksAgo} weeks ago`;
        } else if (monthsAgo < 12) {
            return `${monthsAgo} months ago`;
        } else {
            return `${yearsAgo} years ago`;
        }
    }

    public getMimeTypeFromExtension(url: string): MimeTypeEnum {
        const extension = url.split('.').pop() || '';
        switch (extension.toLowerCase()) {
            case 'webm':
                return MimeTypeEnum.VideoWebm;
            case 'mp4':
                return MimeTypeEnum.VideoMp4;
            // Add more cases for other video formats if needed
            default:
                return MimeTypeEnum.OCTECT_STREAM; // Default MIME type
        }
    }

    isHLSSupported() {
        const video = document.createElement('video');

        // Check for native HLS support
        if (
            video.canPlayType('application/vnd.apple.mpegURL') !== '' ||
            video.canPlayType('application/x-mpegURL') !== ''
        ) {
            return true;
        }

        // Check for MSE support
        if (window.MediaSource) {
            return true;
        }

        return false;
    }

    moveToBeginning<T>(array: T[], criteria: (item: T) => boolean): T[] {
        if (!array || array.length === 0) {
            return array;
        }
        // Separate items matching the criteria and those that don't
        const matchingItems = array.filter(criteria);
        const nonMatchingItems = array.filter((item) => !criteria(item));

        // Concatenate the matching items at the beginning
        return [...matchingItems, ...nonMatchingItems];
    }
}

export function catchErrorTypedAsync<T, E extends new (message?: string) => Error | HttpErrorResponse>(
    promise: Promise<T>,
    errorsToCatch: E[] = []
): Promise<{ data: T | null; error: InstanceType<E> | null }> {
    return promise
        .then((data) => ({
            data,
            error: null,
        }))
        .catch((error) => {
            /// We return any kind of error and not throwing
            if (errorsToCatch.length === 0) {
                return {
                    data: null,
                    error: error,
                };
            }
            const matchedErrorType = errorsToCatch.find((errorType) => error instanceof errorType);
            if (matchedErrorType) {
                return {
                    data: null,
                    error: new matchedErrorType(error.message) as InstanceType<E>,
                };
            }
            /// It means that we don't want to catch the error that was thrown to here (maybe syntax error etc)
            throw error;
        });
}
export function createIconLayer(
    suggestedFormat: IExtendedDesignGroup,
    whereTo: 'design-list' | 'video-idea' | 'library'
) {
    const id = uuidv4();
    const iconLayout: ILayout = {
        _id: id,
        lottiePath: suggestedFormat.chosenDesign.icon,
    };
    switch (whereTo) {
        case 'design-list':
            iconLayout.lottiePath = suggestedFormat.videoIcon;
            break;
        case 'library':
            iconLayout.lottiePath = suggestedFormat.minimalIcon;
            break;
        case 'video-idea':
            iconLayout.lottiePath = suggestedFormat.ideaIcon;
            break;
        default:
            iconLayout.lottiePath = suggestedFormat.minimalIcon;
            break;
    }

    console.log(iconLayout);
    return iconLayout;
}
