import { Injectable } from '@angular/core';
import { FeatureService, PaymentFeatureFlagEvents, PaymentVariantNames } from '../feature-service.service';
import { PlanEnum, User } from '../../models/user';
export interface IUserPaymentStatus {
    needToPay: boolean;
    needToPayNow: boolean;
    plan: PaymentVariantNames | null;
}
@Injectable({
    providedIn: 'root',
})
export class PaymentUtilsService {
    constructor(private featureService: FeatureService) {}

    async checkIfUserNeedsToPayAsync(user: User): Promise<IUserPaymentStatus> {
        if (user?.plan !== PlanEnum.FREE) {
            return { needToPayNow: false, needToPay: false, plan: null };
        }

        if (!(await this.featureService.getFeatureAccessValue('should_see_paywall'))) {
            return { needToPayNow: false, needToPay: false, plan: null };
        }

        const { name: paymentVariableName } = this.featureService.getPaymentVariant();
        if (paymentVariableName === 'trial') {
            return { needToPay: true, needToPayNow: true, plan: 'trial' };
        }

        if (paymentVariableName === 'immediate') {
            if (!user.projectMilestoneAchieved) {
                return { needToPay: true, needToPayNow: false, plan: 'immediate' };
            }

            return { needToPay: true, needToPayNow: true, plan: 'immediate' };
        }

        return { needToPayNow: false, needToPay: false, plan: null };
    }
}
