import { Injectable } from '@angular/core';
import { AuthenticateResponses, AuthenticationService } from './authentication.service';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { UserService } from '../api/auth/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    // Inject both the AuthenticationService and the Router to be able to perform redirects

    isMobile: boolean;

    constructor(
        public auth: AuthenticationService,
        private router: Router,
        private userService: UserService
    ) {
        this.isMobile = this.userService.isMobileUser();
    }

    private async checkAuth(url: string): Promise<boolean> {
        const authResult = await this.auth.authorize(true);

        if (authResult?.response === AuthenticateResponses.AUTHENTICATED && authResult.user) {
            if (url.startsWith('/onboarding')) {
                if (authResult.user.active) {
                    await this.router.navigate(['/dashboard']);
                    return false;
                }
                return true;
            } else {
                if (!authResult.user.active) {
                    console.log('User need to go through onboarding');
                    await this.router.navigate(['/onboarding']);
                    return false;
                }
                return true;
            }
        } else if (authResult?.response === AuthenticateResponses.SERVER_NOT_RESPONSIVE) {
            console.error('Server not responsive');
            this.auth.goToConnectionErrorPage(url);
            return false;
        } else {
            this.auth.logout(url);
            return false;
        }
    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.checkAuth(state.url);
    }

    async canMatch(route: Route, segments: UrlSegment[]): Promise<boolean> {
        const url = '/' + segments.map((segment) => segment.path).join('/');
        return this.checkAuth(url);
    }
}
