import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardingComponent } from '../../pages/private/onboarding/onboarding.component';
import { LoginSuccessComponent } from '../../components/major/auth/login-success/login-success.component';
import { AuthGuard } from '../../services/guards/auth.guard';

const routes: Routes = [
    {
        path: 'authenticate/:token',
        component: LoginSuccessComponent,
    },

    {
        path: '', // Define the path for the parent route
        component: OnboardingComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OnboardingRoutingModule {}
